import * as React from "react";
import { graphql } from "gatsby";
import Post from "../../components/layouts/Post";
import { MDXRenderer } from "gatsby-plugin-mdx";

const BlogPost = (props) => {
  console.log("props:", props);
  const data = props.data;
  return (
    <Post
      pageTitle={data.mdx.frontmatter.title}
      date={data.mdx.frontmatter.date}
      authors={data.mdx.frontmatter.authors}
    >
      <MDXRenderer>{data.mdx.body}</MDXRenderer>
    </Post>
  );
};

export const query = graphql`
  query GetPostById($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        authors
      }
      body
    }
  }
`;

export default BlogPost;
