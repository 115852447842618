import React, { useState } from "react";
import { graphql, Link } from "gatsby";

// large: if it's a "large" header that extends down the screen
export default ({ large = false }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  return menuOpen ? (
    <div
      className={
        "flex p-10 flex-col w-full pt-2  justify-between border-black border-b-4 h-full bg-white text-black"
      }
    >
      <button
        class="btn btn-square btn-ghost self-end"
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          class="inline-block w-6 h-6 stroke-current text-error"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </button>
      <div className="flex-col mr-2 ml-2">
        <div className="flex p-2">
          <Link to="/">
            <h1 className="text-2xl font-bold flex hover:underline">home</h1>
          </Link>
        </div>
        <div className="flex p-2">
          <Link to="/app/">
            <h1 className="text-2xl font-bold flex hover:underline">app</h1>
          </Link>
        </div>
        <div className="flex p-2">
          <Link to="/posts/">
            <h1 className="text-2xl font-bold flex hover:underline">updates</h1>
          </Link>
        </div>

        <div className="flex p-2">
          <Link to="/about/">
            <h1 className="text-2xl font-bold flex hover:underline">about</h1>
          </Link>
        </div>

        <div className="flex p-2">
          <a href="https://github.com/dcalacci/gigbox">
            <h1 className="text-2xl font-bold flex hover:underline">github</h1>
          </a>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={
        "flex p-10 flex-row w-full pt-2  justify-between border-black border-b-4 bg-white text-black " +
        (large ? "h-52 content-start" : "h-16 content-center")
      }
    >
      <Link to="/">
        <div className="flex items-center ">
          <img className="flex-shrink h-12" src="/img/icon.png" />
          <h1 className="text-2xl font-bold flex p-2 ">Gigbox</h1>
        </div>
      </Link>

      <div className="flex-row mr-2 ml-2 sm:hidden">
        <button
          class="btn btn-square btn-ghost"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            class="inline-block w-6 h-6 stroke-current"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
      </div>

      <div className="flex-row mr-2 ml-2 hidden sm:flex">
        <div className="flex p-2">
          <Link to="/app/">
            <h1 className="text-2xl font-bold flex hover:underline">app</h1>
          </Link>
        </div>
        <div className="flex p-2">
          <Link to="/posts/">
            <h1 className="text-2xl font-bold flex hover:underline">updates</h1>
          </Link>
        </div>

        <div className="flex p-2">
          <Link to="/about/">
            <h1 className="text-2xl font-bold flex hover:underline">about</h1>
          </Link>
        </div>

        <div className="flex p-2">
          <a href="https://github.com/dcalacci/gigbox">
            <h1 className="text-2xl font-bold flex hover:underline">github</h1>
          </a>
        </div>
      </div>
    </div>
  );
};
